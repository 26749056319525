//import { TOGGLE_SIDEBAR } from '../mutation-types'

import { ENDPOINT_NEW_COUPLED_SHADE,
          ENDPOINT_DESTROY_COUPLED_SHADE_LINE_ITEM,
          ENDPOINT_COPY_COUPLED_SHADE_LINE_ITEM,
          ENDPOINT_SORT_COUPLED_SHADE_LINE_ITEM
        } from '../../endpoints'

import { mapGetters, mapActions } from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import { v4 as uuidv4 } from 'uuid'

export const SHADE_LINE_ITEM_DEFAULT_VALUES = {
  product_model_shade_type: 'Interior',
  back_fascia: 0,
  wrapped_fascia_fabric: null,
  drive_type: "M-I",
  errors: {},
  handedness: 'left',
  hardware_color: 'black',
  has_side_channel: 0,
  has_trim: 0,
  hasChanges: false,
  hold_down: 1,
  is_door: 0,
  isNew: false,
  mount_type: "wall",
  order_item_assembly_hembar_composition: "A",
  order_item_assembly_errors: {},
  override_deflection: 0,
  pocket_include_closure: 0,
  product_model_dual_shade: 0,
  product_model_guided_cable: 0,
  roll_type: 'standard',
  trim_type: null
};

export const FABRIC_ONLY_SHADE_LINE_ITEM_DEFAULT_VALUES = {
  product_model_shade_type: 'Interior',
  handedness: null,
  hardware_color: null,
  drive_type: 'M-I',
  mount_type: null,
  is_door: 0,
  hold_down: 0,
  motor_manufacturer_id: null,
  motor_type: null,
  motor_power: null,
  motor_sound: null,
  fascia_type: null,
  back_fascia: 0,
  preferred_fascia_size: null,
  fascia_color: null,
  fascia_endcap_color: null,
  fascia_color_custom_manufacturer: null,
  fascia_color_custom_name: null,
  wrapped_fascia_fabric: null,
  has_side_channel: 0,
  side_channel_color: null,
  side_channel_color_custom_manufacturer: null,
  side_channel_color_custom_name: null,
  has_trim: 0,
  trim_type: null,
  trim_color: null,
  trim_color_custom_manufacturer: null,
  trim_color_custom_name: null,
  pocket_height: null,
  pocket_depth: null,
  pocket_include_closure: null,
  pocket_closure_size: null,
  errors: {},
  order_item_assembly_errors: {},
};

export const EXTERIOR_SHADE_LINE_ITEM_DEFAULT_VALUES = {
  product_model_shade_type: 'Exterior',
  product_model_exterior_mfg: 'Veue',
  drive_type: 'M-I',
  is_door: 0,
  hold_down: 0,
  handedness: null,
  product_model_dual_shade: 0,
  product_model_guided_cable: 0,
  product_model_bottom_up: null,
  product_model_drive_mechanism: 'motor',
  motor_manufacturer_id: null,
  dual_fabric_id: null,
  dual_fabric_openness_factor_id: null,
  dual_fabric_attribute_id: null,
  hembar_style: null,
  hembar_color: null,
  hembar_endcap_color: null,
  fascia_type: null,
  back_fascia: null,
  preferred_fascia_size: null,
  fascia_color: null,
  fascia_endcap_color: null,
  fascia_color_custom_manufacturer: null,
  fascia_color_custom_name: null,
  wrapped_fascia_fabric: null,
  has_side_channel: 0,
  side_channel_color: null,
  side_channel_color_custom_manufacturer: null,
  side_channel_color_custom_color: null,
  has_trim: 0,
  trim_type: null,
  trim_color: null,
  trim_color_custom_manufacturer: null,
  trim_color_custom_name: null,
  pocket_height: null,
  pocket_depth: null,
  pocket_include_closure: 0,
  pocket_closure_size: null,
};

export const COUPLED_SHADE_CHILD_LOCKED_PROPS = [
  'phase', 'floors', 'room',
  'quantity', 'actual_length', 'handedness', 'hardware_color',
  'hold_down', 'mount_type', 'is_door',
  'roll_type', 'product_model_dual_shade', 'product_model_guided_cable', 'product_model_bottom_up',
  'product_model_drive_mechanism', 'motor_manufacturer_id', 'motor_type', 'motor_power', 'motor_sound',
  'fabric_id', 'fabric_openness_factor_id', 'fabric_attribute_id', 'dual_fabric_id', 'dual_fabric_openness_factor_id', 'dual_fabric_attribute_id',
  'fascia_type', 'back_fascia', 'wrapped_fascia_fabric', 'preferred_fascia_size', 'fascia_color', 'fascia_endcap_color', 'fascia_color_custom_manufacturer', 'fascia_color_custom_name',
  'has_side_channel', 'side_channel_color', 'side_channel_color_custom_manufacturer', 'side_channel_color_custom_name',
  'hembar_style', 'hembar_color', 'hembar_endcap_color', 'order_item_assembly_hembar_composition',
  'has_trim', 'trim_type', 'trim_color', 'trim_color_custom_manufacturer', 'trim_color_custom_name',
  'pocket_height', 'pocket_depth', 'pocket_include_closure', 'pocket_closure_size',
];

export const FABRIC_PANEL_ONLY_PERMITTED_FIELDS = [
  'phase', 'floors', 'room',
  'quantity', 'actual_width', 'actual_length',
  'shade_notes', 'order_item_assembly_notes',
  'roll_type', 'product_model_dual_shade', 'product_model_guided_cable', 'product_model_bottom_up',
  'product_model_drive_mechanism',
  'fabric_id', 'fabric_openness_factor_id', 'fabric_attribute_id',
  'dual_fabric_id', 'dual_fabric_openness_factor_id', 'dual_fabric_attribute_id',
  'hembar_style', 'hembar_color', 'hembar_endcap_color'
];


export default {
  namespaced: true,

  //
  // State
  //
  state: {
    byId: {},
    initialStateById: {},
    byGroupId: {},
    allIds: [],
    errors: {}
  },

  //
  // Getters
  //
  getters: {
    //
    // List/multiple object getters
    shadeLineItemsById: state => state.byId,

    //
    // Check if shade is exterior shade
    isExteriorShade: (state, getters) => (id) => {
      let shadeLineItem = getters.find(id),
          shadeType = ( shadeLineItem.product_model_shade_type ) ? shadeLineItem.product_model_shade_type.toLowerCase() : null;
      return ( shadeType == 'exterior' ) ? true : false;
    },

    //
    // Check if shade has fascia (by shade id)
    hasFascia: (state, getters) => (id) => {
      var unit = getters.find(id);
      var fasciaOptions = [
        'Fascia',
        'Fabric Wrapped Fascia',
        'LV Cassette w/Fabric Insert',
        'RO P95 Cassette w/o Fabric Wrap',
        'RO P95 Cassette w/Fabric Wrap'
      ];

      return ( fasciaOptions.includes(unit.fascia_type ) ) ? true : false;
    },

    //
    // Check if shade has fabric wrapped fascia (by shade id)
    hasFabricWrappedFascia: (state, getters) => (id) => {
      var unit = getters.find(id);
      var fasciaOptions = [
        'Fabric Wrapped Fascia',
        'LV Cassette w/Fabric Insert',
        'RO P95 Cassette w/Fabric Wrap'
      ];

      return ( fasciaOptions.includes(unit.fascia_type ) ) ? true : false;
    },

    //
    // Get list of shade errors by shade id
    errorsByRecordId: (state) => (id) => {
      if( state.errors.hasOwnProperty(id) ) {
        return state.errors[id];
      } else {
        return {};
      }
    },

    //
    // Find shade line item by shade id
    find: state => id => state.byId[id],

    //
    // Get list of all shade line items
    list: (state, getters) => {
      return state.allIds.map(id => getters.find(id));
    },

    //
    // Get shades initial state by shade id
    getInitialStateById: state => id => state.initialStateById[id],

    //
    // Get fabric summary text for shade by shade id
    fabricSummaryText: (state, rootState, store, getters) => (id) => {

      let shadeUnit = getters['shadeLineItems/find'](id),
          isExteriorShade = getters['shadeLineItems/isExteriorShade'](id);

      if( isExteriorShade ) {
        var fabricOptions = store.estimationSelectOptions.primaryShadeSelectOptions.exteriorFabricOptions;
      } else {
        var fabricOptions = store.estimationSelectOptions.primaryShadeSelectOptions.interiorFabricOptions;
      }

      if( typeof shadeUnit === 'undefined' || !shadeUnit.fabric_id || !shadeUnit.fabric_openness_factor_options.length || !shadeUnit.fabric_attribute_options.length ) {
        return 'Not Specified';
      }


      let fabricMan = '';
      let fabricManufacturer = '';
      let fabricOpenness = shadeUnit.fabric_openness_factor_options.find(fabOpenness => fabOpenness.id == shadeUnit.fabric_openness_factor_id);
      let fabric = shadeUnit.fabric_attribute_options.find(fabColor => fabColor.id == shadeUnit.fabric_attribute_id);

      for(fabricManufacturer in fabricOptions) {
        fabricMan = fabricOptions[fabricManufacturer].find(fabMfr => fabMfr.id == shadeUnit.fabric_id);
        if( fabricMan ) {
          break;
        }
      }

      if( typeof fabricMan != 'undefined' && fabricMan.hasOwnProperty('name') && fabricOpenness && fabric ) {
        return fabricMan.name + ' ' + fabric.color + ' ' + fabricOpenness.name;
      } else {
        return 'Not Specified';
      }
    },

    //
    // Get dual fabric summary text for shade by id
    dualFabricSummaryText: (state, rootState, store, getters) => (id) => {
      let shadeUnit = getters['shadeLineItems/find'](id),
          isExteriorShade = getters['shadeLineItems/isExteriorShade'](id);

      if( isExteriorShade ) {
        var fabricOptions = store.estimationSelectOptions.primaryShadeSelectOptions.exteriorFabricOptions;
      } else {
        var fabricOptions = store.estimationSelectOptions.primaryShadeSelectOptions.interiorFabricOptions;
      }

      if( typeof shadeUnit === 'undefined' || !shadeUnit.dual_fabric_id || !shadeUnit.dual_fabric_openness_factor_options.length || !shadeUnit.dual_fabric_attribute_options.length ) {
        return null;
      }

      let fabricMan = '';
      let fabricManufacturer = '';
      let fabric = shadeUnit.dual_fabric_attribute_options.find(fabColor => fabColor.id == shadeUnit.dual_fabric_attribute_id);
      let fabricOpenness = shadeUnit.dual_fabric_openness_factor_options.find(fabOpenness => fabOpenness.id == shadeUnit.dual_fabric_openness_factor_id);

      for(fabricManufacturer in fabricOptions) {
        fabricMan = fabricOptions[fabricManufacturer].find(fabMfr => fabMfr.id == shadeUnit.dual_fabric_id);
        if( fabricMan ) {
          break;
        }
      }

      if( fabricMan.hasOwnProperty('name') && fabricOpenness && fabric ) {
        return fabricMan.name + ' ' + fabric.color + ' ' + fabricOpenness.name;
      } else {
        return null;
      }
    },

    //
    //Retrieve/return fabric only shade line item defaults
    fabricOnlyShadeDefaults: (state) => {
      return FABRIC_ONLY_SHADE_LINE_ITEM_DEFAULT_VALUES;
    },

    //
    //Retrieve/return array of coupled shade locked properties
    coupledShadeChildLockedProps: (state) => {
      return COUPLED_SHADE_CHILD_LOCKED_PROPS;
    },

    //
    //Retrieve/return array of permitted fields for fabric panel only shade units
    fabricPanelOnlyPermittedFields: (state) => {
      return FABRIC_PANEL_ONLY_PERMITTED_FIELDS;
    },

    //
    // vuex-map-fields plugin
    //
    getField,
  },


  //
  // Actions
  //
  actions: {
    //
    // Add Shade Line Items to the store (initial load)
    //
    applyShadeLineItems({commit, dispatch}, shadeLineItems) {
      dispatch('setFormErrors', {});

      if( shadeLineItems ) {
        shadeLineItems.forEach(function(shadeLineItem, index) {
          shadeLineItem = _.mergeWith(shadeLineItem, SHADE_LINE_ITEM_DEFAULT_VALUES, function(sliValue, defaultValue) {
            if( (_.isNull(sliValue) || _.isUndefined(sliValue)) && (!_.isNull(defaultValue) && !_.isUndefined(defaultValue)) ) {
              return defaultValue;
            } else {
              return sliValue;
            }
          });

          shadeLineItem.isInnerCoupledShade = ( index > 0 || shadeLineItem.isInnerCoupledShade == true ) ? true : false;

          if( shadeLineItems.isNew == true ) {
            shadeLineItem.isNew = true;
            shadeLineItem.isMaximized = true;
          } else {
            shadeLineItem.isMaximized = false;
            shadeLineItem.showShadeSettings = false;
          }
          shadeLineItem.hasChanges = false;

          //Make sure that actual width and length apply the formatted version
          shadeLineItem.actual_width = shadeLineItem.actual_width_formatted;
          shadeLineItem.actual_length = shadeLineItem.actual_length_formatted;

          //flatten the object for easier use with vuex
          shadeLineItem.order_item_assembly_hembar_composition = shadeLineItem.order_item_assembly.hembar_composition;
          shadeLineItem.order_item_assembly_spring_position = shadeLineItem.order_item_assembly.spring_position;
          shadeLineItem.order_item_assembly_notes = shadeLineItem.order_item_assembly.notes;

          commit('ADD_SHADE_LINE_ITEM', shadeLineItem);
        });
      }
    },

    //
    // Update EXISTING shade line items (already created within the application and in the store)
    //
    updateExistingShadeLineItems({commit, dispatch, getters}, shadeLineItems) {
      dispatch('setFormErrors', {});

      if( shadeLineItems.length > 0 ) {
        shadeLineItems.forEach(function(shadeLineItem, index) {
          //Set innerCoupledShade
          shadeLineItem.isInnerCoupledShade = ( index > 0 ) ? true : false;

          //Apply the defaults
          shadeLineItem.isNew = false;
          shadeLineItem.isMaximized = false;
          shadeLineItem.showShadeSettings = false;
          shadeLineItem.hasChanges = false;
          shadeLineItem.errors = {};

          //Make sure that actual width and length apply the formatted version
          shadeLineItem.actual_width = shadeLineItem.actual_width_formatted;
          shadeLineItem.actual_length = shadeLineItem.actual_length_formatted;

          //flatten the object for easier use with vuex
          shadeLineItem.order_item_assembly_hembar_composition = shadeLineItem.order_item_assembly.hembar_composition;
          shadeLineItem.order_item_assembly_spring_position = shadeLineItem.order_item_assembly.spring_position;
          shadeLineItem.order_item_assembly_notes = shadeLineItem.order_item_assembly.notes;

          commit('UPDATE_EXISTING_SHADE_LINE_ITEM', shadeLineItem);
        });
      }
    },

    //
    // Update NEW shade line items
    //  This method is for NEWLY saved shade group line items. These already exist in the state BUT their IDs need to be updated to match the
    //  actual record ids that are saved to the application database.
    //
    updateNewShadeLineItems({commit, dispatch}, shadeLineItems) {
      dispatch('setFormErrors', {});

      if( shadeLineItems.length > 0 ) {
        shadeLineItems.forEach(function(shadeLineItem, index) {
          //Set innerCoupledShade
          shadeLineItem.isInnerCoupledShade = ( index > 0 ) ? true : false;

          //Apply the defaults
          shadeLineItem.isNew = false;
          shadeLineItem.isMaximized = false;
          shadeLineItem.errors = {};

          //flatten the object for easier use with vuex
          shadeLineItem.order_item_assembly_hembar_composition = shadeLineItem.order_item_assembly.hembar_composition;
          shadeLineItem.order_item_assembly_spring_position = shadeLineItem.order_item_assembly.spring_position;
          shadeLineItem.order_item_assembly_notes = shadeLineItem.order_item_assembly.notes;

          commit('UPDATE_NEW_SHADE_LINE_ITEM', shadeLineItem);
        });
      }
    },

    //
    // Delete/remove all line items from a given shade group (clean up when shade group is deleted)
    //
    deleteShadeGroupLineItems({commit, state, rootState, dispatch}, shadeGroupId) {
      commit('DESTROY_SHADE_GROUP_LINE_ITEMS', shadeGroupId);
    },

    //
    // Delete a singular shade line item from a shade group
    //  This is called when a SAVED (in the application db) inner coupled (non-parent) shade is deleted from a shade group
    //
    deleteShadeLineItem({commit, state, getters, rootState, dispatch}, id) {
      var shadeLineItem = getters.find(id);

      if( shadeLineItem.isNew == false ) {
        axios.delete(ENDPOINT_DESTROY_COUPLED_SHADE_LINE_ITEM + shadeLineItem.id)
        .then((response) => {
          //this is now saved so it's no longer new...
          response.data.data.shadeGroup.isNew = false;
          response.data.data.shadeGroup.isUpdate = true;

          //Remove the shade line item...
          commit('DESTROY_SHADE_LINE_ITEM', shadeLineItem);

          //Dispatch the updated shade group (response) data to applyShadeGroups. That action should take care of everything we need...
          dispatch('shadeGroups/applyShadeGroups', [response.data.data.shadeGroup], {root: true});

          if( response.data.data.hasOwnProperty('orderProposal') ) {
            //update the orderProposal
            dispatch('orderProposal/applyOrderProposal', response.data.data.orderProposal, {root: true});
          }

          flash(response.data.status_type, response.data.status);
        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status);
        });

      } else {
        commit('DESTROY_SHADE_LINE_ITEM', shadeLineItem);

        var payload = {
          shadeLineItemId: shadeLineItem.id,
          shadeGroupId: shadeLineItem.shade_group_id
        };
        dispatch('shadeGroups/deleteNewShadeLineItemFromShadeGroup', payload, {root: true});
      }
    },

    //
    // Copy shade line item.
    //  This action is used on inner coupled shades (parent shades will trigger the copy action on the shade group level)
    //
    copyShadeLineItem({commit, getters, dispatch, rootState}, id) {
      var shadeLineItem = getters.find(id);

      if( shadeLineItem.isNew == false ) {
        axios.post(ENDPOINT_COPY_COUPLED_SHADE_LINE_ITEM + shadeLineItem.id)
        .then((response) => {
          //response.data.data.shadeGroup.isUpdate = true;

          var sg = response.data.data.shadeGroup;
          sg.shadeLineItems = sg.order_items;
            delete sg.order_items;

          sg.shadeLineItems.forEach(function(sli, index) {
            var storedSli = getters.find(sli.id);
            if( typeof storedSli === 'undefined' ) {
              sli.isInnerCoupledShade = true;
              dispatch('applyShadeLineItems', [sli]);
            } else {
              dispatch('updateExistingShadeLineItems', [sli]);
            }
          });
          dispatch('shadeGroups/updateShadeGroupLineItems', sg, {root: true});

          if( response.data.data.hasOwnProperty('orderProposal') ) {
            //update the orderProposal
            dispatch('orderProposal/applyOrderProposal', response.data.data.orderProposal, {root: true});
          }

          flash(response.data.status_type, response.data.status);
        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status);
        });

      } else {
        //For the shade line items
        var clonedShadeLineItem = _.cloneDeep(shadeLineItem);
        var shadeLineItems = [ clonedShadeLineItem ];
        shadeLineItems.isNew = true;
        dispatch('applyShadeLineItems', shadeLineItems);

        //for the shade group
        var payload = {
          shadeGroupId: clonedShadeLineItem.shade_group_id,
          shadeLineItem: clonedShadeLineItem
        };
        dispatch('shadeGroups/addCoupledShadeToShadeGroup', payload, {root: true});
      }
    },

    //
    // Merge the selected shade unit config settings into the specified shade line item
    //
    mergeShadeUnitConfig({commit, getters, rootGetters}, payload) {
      var shadeUnitConfig = _.clone(rootGetters['shadeUnitConfigs/find'](payload.shadeUnitConfigId)),
          isExteriorShadeUnitConfig = rootGetters['shadeUnitConfigs/isExteriorShade'](payload.shadeUnitConfigId);

      shadeUnitConfig.shade_unit_config_id = shadeUnitConfig.id;
        delete shadeUnitConfig.id;
        delete shadeUnitConfig.isNew;
        delete shadeUnitConfig.isMaximized;
        delete shadeUnitConfig.hasChanges;
        delete shadeUnitConfig.created_at;
        delete shadeUnitConfig.deleted_at;
        delete shadeUnitConfig.updated_at;

      var payload = {
        shadeLineItemId: payload.shadeLineItemId,
        configSettings: shadeUnitConfig
      };

      if( isExteriorShadeUnitConfig ) {
        commit('MERGE_EXTERIOR_SHADE_UNIT_CONFIG_SETTINGS', payload);
      } else {
        commit('MERGE_INTERIOR_SHADE_UNIT_CONFIG_SETTINGS', payload);
      }
    },

    //
    // Apply validation errors to shade line items
    //
    setFormErrors({commit, state, getters}, payload) {
      commit('SET_SHADE_LINE_ITEM_ERRORS', payload);
    },

    //
    // Add a coupled shade line item
    //
    addCoupledShade({commit, state, getters, rootGetters, rootState, dispatch}, shadeGroupId) {
      var shadeGroup = rootGetters['shadeGroups/find'](shadeGroupId);

      axios.post(ENDPOINT_NEW_COUPLED_SHADE + shadeGroup.shade_order_id  + '/' + shadeGroup.id)
      .then((response) => {
        var shadeLineItem = response.data.data.orderItem;

        //This needs to be handled on the server end...
        if( shadeGroup.isNew == true && shadeGroup.shadeLineItems.length) {
          const parentShade = shadeGroup.shadeLineItems[0];

          COUPLED_SHADE_CHILD_LOCKED_PROPS.forEach(function(prop) {
            shadeLineItem[prop] = parentShade[prop];
          });
        }

        var payload = {
          shadeGroupId: shadeGroup.id,
          shadeLineItem: shadeLineItem
        };
        dispatch('shadeGroups/addCoupledShadeToShadeGroup', payload, {root: true});

        var shadeLineItems = [ shadeLineItem ];
        shadeLineItems.isNew = true;
        dispatch('applyCoupledShadeLineItems', shadeLineItems);
      })
      .catch(error => {
        //show the error message
        //flash(error.response.data.status_type, error.response.data.status);
      });
    },

    //
    // Add Shade Line Items to the store (initial load)
    //
    applyCoupledShadeLineItems({commit}, shadeLineItems) {
      if( shadeLineItems.length > 0 ) {
        shadeLineItems.forEach(shadeLineItem => {
          shadeLineItem = _.mergeWith(shadeLineItem, SHADE_LINE_ITEM_DEFAULT_VALUES, function(sliValue, defaultValue) {
            if( (_.isNull(sliValue) || _.isUndefined(sliValue)) && (!_.isNull(defaultValue) && !_.isUndefined(defaultValue)) ) {
              return defaultValue;
            } else {
              return sliValue;
            }
          });

          if( shadeLineItems.isNew == true ) {
            shadeLineItem.isNew = true;
          }
          shadeLineItem.isInnerCoupledShade = true;

          commit('ADD_COUPLED_SHADE_TO_SHADE_GROUP', shadeLineItem);
        });
      }
    },

    //
    // Apply Default Shade Properties when Fabric Only is selected
    //
    applyDefaultShadePropsForFabricOnly({commit, getters, rootGetters}, id) {
      let shadeLineItem = _.clone(getters.find(id)),
          shadeUnitDefaults = FABRIC_ONLY_SHADE_LINE_ITEM_DEFAULT_VALUES,
          permittedFabricPanelOnlyFields = FABRIC_PANEL_ONLY_PERMITTED_FIELDS,
          shadeUnitConfig = ( shadeLineItem.shade_unit_config_id ) ? rootGetters['shadeUnitConfigs/find'](shadeLineItem.shade_unit_config_id) : null;

      //If this shade item is assigned to a shade unit config and the config unit is not fabric_only, remove the unit config reference.
      if( shadeUnitConfig && shadeUnitConfig.product_model_drive_mechanism != 'fabric_only' ) {
        shadeLineItem.shade_unit_config_id = null;
      }

      Object.entries(shadeUnitDefaults).forEach(([key, value]) => {
        if( !permittedFabricPanelOnlyFields.includes(key) ) {
          shadeLineItem[key] = value;
        }
      });

      commit('UPDATE_EXISTING_SHADE_LINE_ITEM', shadeLineItem);
    },

    //
    // Remove Default Shade Properties when Fabric Only is deselected
    //  (product_model_drive_mechanism changes from 'fabric_only' to some other value)
    //
    removeShadePropsForFabricOnly({commit, getters, rootGetters}, id) {
      let shadeLineItem = _.clone(getters.find(id)),
          shadeUnitDefaults = SHADE_LINE_ITEM_DEFAULT_VALUES,
          shadeUnitConfig = ( shadeLineItem.shade_unit_config_id ) ? rootGetters['shadeUnitConfigs/find'](shadeLineItem.shade_unit_config_id) : null;

      //If this shade item is assigned to a shade unit config and the config unit IS fabric_only,
      //remove the unit config reference because this is no longer a fabric only unit
      if( shadeUnitConfig && shadeUnitConfig.product_model_drive_mechanism == 'fabric_only' ) {
        shadeLineItem.shade_unit_config_id = null;
      }
      commit('UPDATE_EXISTING_SHADE_LINE_ITEM', shadeLineItem);
    },

  //
  // Actions for Select Options
  //
    //
    // Set Fabric Openness Factor Options for a given shade line item.
    setFabricOpennessFactorOptions({commit, getters, dispatch}, payload) {
      let targetUnit = getters.find(payload.id),
          reqParams = new URLSearchParams();
            reqParams.append('fabric_manufacturer_id', payload.fabric_manufacturer_id);

      dispatch('estimationSelectOptions/getFabricOpennessFactorOptions', {reqParams: reqParams}, {root: true})
      .then(response => {
        let commitData = {
          shadeLineItem: targetUnit,
          fabric_number: payload.fabric_number,
          fabric_openness_options: response.data.data,
        };
        commit('SET_SHADE_LINE_ITEM_FABRIC_OPENNESS_FACTOR_OPTIONS', commitData);

      }, error => {
        flash(error.response.data.status_type, error.response.data.status);
      });
    },

    //
    // Set the fabric color options for a given shade line item.
    setFabricColorOptions({commit, getters, dispatch}, payload) {
      let targetUnit = getters.find(payload.id),
          reqParams = new URLSearchParams();
            reqParams.append('fabric_openness_id', payload.fabric_openness_id);

      dispatch('estimationSelectOptions/getFabricColorOptions', {reqParams: reqParams}, {root: true})
      .then(response => {
        let commitData = {
          shadeLineItem: targetUnit,
          fabric_number: payload.fabric_number,
          fabric_color_options: response.data.data,
        };
        commit('SET_SHADE_LINE_ITEM_FABRIC_COLOR_OPTIONS', commitData);

      }, error => {
        flash(error.response.data.status_type, error.response.data.status);
      });
    },


    setMotorTypeOptions({commit, getters, dispatch}, payload) {
      let targetUnit = getters.find(payload.id),
          isExteriorShade = getters.isExteriorShade(payload.id),
          reqParams = new URLSearchParams();
            reqParams.append('motor_manufacturer_id', targetUnit.motor_manufacturer_id);

      return new Promise((resolve, reject) => {
        dispatch('estimationSelectOptions/getMotorTypeOptions', {reqParams: reqParams, isExteriorShade: isExteriorShade}, {root: true})
        .then(response => {
          let commitData = {
            id: payload.id,
            isExteriorShade: isExteriorShade,
            motor_type_options: response.motorTypeOptions
          };
          commit('SET_SHADE_LINE_ITEM_MOTOR_TYPE_OPTIONS', commitData);
          resolve(response);
        }, error => {
          flash(error.response.data.status_type, error.response.data.status);
          reject(error);
        });
      });
    },

    //
    // Set Motor Power select options for a given shade line item
    setMotorPowerOptions({commit, getters, dispatch}, payload) {
      let targetUnit = getters.find(payload.id),
          isExteriorShade = getters.isExteriorShade(payload.id),
          reqParams = new URLSearchParams();
            reqParams.append('motor_manufacturer_id', targetUnit.motor_manufacturer_id);
            reqParams.append('motor_type', targetUnit.motor_type);

      return new Promise((resolve, reject) => {
        dispatch('estimationSelectOptions/getMotorPowerOptions', {reqParams: reqParams, isExteriorShade: isExteriorShade}, {root: true})
        .then(response => {
          let commitData = {
            id: payload.id,
            isExteriorShade: isExteriorShade,
            motor_power_options: response.motorPowerOptions
          };
          commit('SET_SHADE_LINE_ITEM_MOTOR_POWER_OPTIONS', commitData);
          resolve(response);
        }, error => {
          flash(error.response.data.status_type, error.response.data.status);
          reject(error);
        });
      });
    },

    //
    // Set Motor Sound select options for a given shade line item
    setMotorSoundOptions({commit, getters, dispatch}, payload) {
      let targetUnit = getters.find(payload.id),
          isExteriorShade = getters.isExteriorShade(payload.id),
          reqParams = new URLSearchParams();
            reqParams.append('motor_manufacturer_id', targetUnit.motor_manufacturer_id);
            reqParams.append('motor_type', targetUnit.motor_type);
            reqParams.append('motor_power', targetUnit.motor_power);
            reqParams.append('is_exterior_shade', (isExteriorShade == true) ? 1 : 0);

      return new Promise((resolve, reject) => {
        dispatch('estimationSelectOptions/getMotorSoundOptions', {reqParams: reqParams}, {root: true})
        .then(response => {
          let commitData = {
            id: payload.id,
            isExteriorShade: isExteriorShade,
            motor_sound_options: response.data.data
          };
          commit('SET_SHADE_LINE_ITEM_MOTOR_SOUND_OPTIONS', commitData);
          resolve(response);
        }, error => {
          flash(error.response.data.status_type, error.response.data.status);
          reject(error);
        });
      });
    },

    //
    // Update sort order
    updateSortOrder({commit, getters, rootGetters}, payload) {
      let shadeGroup = rootGetters['shadeGroups/find'](payload.shadeGroupId);

      if( shadeGroup.isNew ) {
        //commit('SET_SORT_ORDER_FOR_NEW_SHADE_GROUP_LINE_ITEMS', payload);
        commit('SET_SORT_ORDER', payload.shadeLineItems);

      } else {
        var sortOrder = payload.shadeLineItems.map(shadeLineItem => shadeLineItem.id);
        var submitData = {
              _method: 'PUT',
              sort_order: sortOrder
            };

        axios.post(ENDPOINT_SORT_COUPLED_SHADE_LINE_ITEM + shadeGroup.id, submitData)
        .then((response) => {
          commit("SET_SORT_ORDER", response.data.data.shadeGroup.order_items);
          //flash(response.data.status_type, response.data.status);
        })
        .catch(error => {
          //show the error message
          flash(error.response.data.status_type, error.response.data.status);
        });
      }
    }
  },


  //
  // Mutations
  //
  mutations: {
    ADD_SHADE_LINE_ITEM(state, item) {
      //Set state for shade line items byId
      Vue.set(state.byId, item.id, item);

      //Set the initial state (so we can easily revert to previous object if necessary)
      Vue.set(state.initialStateById, item.id, _.cloneDeep(item));

      if( typeof state.byGroupId[item.shade_group_id] == 'undefined' ) {
        state.byGroupId = Object.assign({}, state.byGroupId, { [item.shade_group_id]: { shadeLineItems: [] } });
      }
      state.byGroupId[item.shade_group_id].shadeLineItems.push(item);

      //Set state for all shade line item ids
      if (state.allIds.includes(item.id)) return;
      state.allIds.push(item.id);
    },

    UPDATE_EXISTING_SHADE_LINE_ITEM(state, updatedItem) {
      var item = state.byId[updatedItem.id];

      if( typeof item == 'undefined' || item == null ) {
        item = state.byId[updatedItem.old_id];
      }

      item = _.merge(item, updatedItem);
      item.errors = {};
      item.order_item_assembly_errors = updatedItem.order_item_assembly_errors;

      //Set state for shade line items byId
      Vue.set(state.byId, item.id, item);

      //Set the initial state (so we can easily revert to previous object if necessary)
      Vue.set(state.initialStateById, item.id, _.cloneDeep(item));

      //And the group record...
      var index = state.byGroupId[item.shade_group_id].shadeLineItems.map(s => s.id).indexOf(item.id);
      state.byGroupId[item.shade_group_id].shadeLineItems.splice(index, 1, item);
    },

    UPDATE_NEW_SHADE_LINE_ITEM(state, item) {
      //Set state for shade line items byId
      Vue.set(state.byId, item.id, item);

      //Set the initial state (so we can easily revert to previous object if necessary)
      Vue.set(state.initialStateById, item.id, _.cloneDeep(item));

      if( typeof state.byGroupId[item.shade_group_id] == 'undefined' ) {
        state.byGroupId = Object.assign({}, state.byGroupId, { [item.shade_group_id]: { shadeLineItems: [] } });
      }
      state.byGroupId[item.shade_group_id].shadeLineItems.push(item);

      //Set state for all shade line item ids
      if (state.allIds.includes(item.id)) return;
      state.allIds.push(item.id);
    },

    DESTROY_SHADE_LINE_ITEM(state, item) {
      var index = state.allIds.indexOf(item.id);
      state.allIds.splice(index, 1);

      delete state.byId[item.id];

      state.byGroupId[item.shade_group_id].shadeLineItems.forEach(function(shadeLineItem, index) {
        if( shadeLineItem.id == item.id ) {
          state.byGroupId[item.shade_group_id].shadeLineItems.splice(index, 1);
        }
      });
    },

    DESTROY_SHADE_GROUP_LINE_ITEMS(state, shadeGroupId) {
      var shadeGroup = state.byGroupId[shadeGroupId];

      if( shadeGroup.shadeLineItems ) {
        shadeGroup.shadeLineItems.forEach(shadeLineItem => {
          delete state.byId[shadeLineItem.id];
          delete state.initialStateById[shadeLineItem.id];

          var index = state.allIds.indexOf(shadeLineItem.id);
          state.allIds.splice(index, 1);
        });
      }

      delete state.byGroupId[shadeGroupId];
      state.byGroupId = JSON.parse(JSON.stringify(state.byGroupId));
    },

    MERGE_INTERIOR_SHADE_UNIT_CONFIG_SETTINGS(state, payload) {
      var item = state.byId[payload.shadeLineItemId];

      //merge the data
      item = _.merge(item, payload.configSettings);

      //Set state for shade line items byId
      Vue.set(state.byId, item.id, item);
    },

    MERGE_EXTERIOR_SHADE_UNIT_CONFIG_SETTINGS(state, payload) {
      var item = state.byId[payload.shadeLineItemId];

      //merge the data
      item = _.merge(item, EXTERIOR_SHADE_LINE_ITEM_DEFAULT_VALUES);
      item = _.merge(item, payload.configSettings);

      //Set state for shade line items byId
      Vue.set(state.byId, item.id, item);
    },

    SET_SHADE_LINE_ITEM_ERRORS(state, payload) {
      var shadeGroupId = payload.shadeGroupId,
          errors = payload.errors,
          shadeGroup = state.byGroupId[shadeGroupId];

      for( const index in errors ) {
        var targetLineItemId = shadeGroup.shadeLineItems[index].id,
            targetLineItem = state.byId[targetLineItemId];
        targetLineItem.errors = errors[index];

        //Set state for shade line items byId
        Vue.set(state.byId, targetLineItem.id, targetLineItem);

        //Set the initial state
        Vue.set(state.initialStateById, targetLineItem.id, _.cloneDeep(targetLineItem));
      }
    },

    ADD_COUPLED_SHADE_TO_SHADE_GROUP(state, item) {
      //Set state for shade line items byId
      Vue.set(state.byId, item.id, item);

      //Set the initial state (so we can easily revert to previous object if necessary)
      Vue.set(state.initialStateById, item.id, _.cloneDeep(item));

      if( typeof state.byGroupId[item.shade_group_id] == 'undefined' ) {
        state.byGroupId = Object.assign({}, state.byGroupId, { [item.shade_group_id]: { shadeLineItems: [] } });
      }
      state.byGroupId[item.shade_group_id].shadeLineItems.push(item);

      //Set state for all shade line item ids
      if (state.allIds.includes(item.id)) return;
      state.allIds.push(item.id);
    },


  //
  // Fabric Select Option Setters
  //
    SET_SHADE_LINE_ITEM_FABRIC_OPENNESS_FACTOR_OPTIONS(state, payload) {
      var shadeId = payload.shadeLineItem.id;

      if( payload.fabric_number == 'fabric_2' ) {
        state.byId[shadeId].dual_fabric_openness_factor_id = null;
        state.byId[shadeId].dual_fabric_openness_factor_options = payload.fabric_openness_options;
        state.byId[shadeId].dual_fabric_attribute_id = null;
        state.byId[shadeId].dual_fabric_attribute_options = {};
      } else {
        state.byId[shadeId].fabric_openness_factor_id = null;
        state.byId[shadeId].fabric_openness_factor_options = payload.fabric_openness_options;
        state.byId[shadeId].fabric_attribute_id = null;
        state.byId[shadeId].fabric_attribute_options = {};
      }
    },

    SET_SHADE_LINE_ITEM_FABRIC_COLOR_OPTIONS(state, payload) {
      var shadeId = payload.shadeLineItem.id;
      if( payload.fabric_number == 'fabric_2' ) {
        state.byId[shadeId].dual_fabric_attribute_id = null;
        state.byId[shadeId].dual_fabric_attribute_options = payload.fabric_color_options;
      } else {
        state.byId[shadeId].fabric_attribute_id = null;
        state.byId[shadeId].fabric_attribute_options = payload.fabric_color_options;
      }
    },

  //
  // Motor Select Option Setters
  //
    SET_SHADE_LINE_ITEM_MOTOR_TYPE_OPTIONS(state, payload) {
      var shadeId = payload.id;
      if( payload.isExteriorShade ) {
        state.byId[shadeId].motor_type = 'Wireless';
        state.byId[shadeId].motor_power = 'AC Exterior';
        state.byId[shadeId].motor_type_options = payload.motor_type_options;
        state.byId[shadeId].motor_sound_options = [];
      } else {
        state.byId[shadeId].motor_type_options = payload.motor_type_options;
        state.byId[shadeId].motor_power_options = [];
        state.byId[shadeId].motor_sound_options = [];
      }
    },

    SET_SHADE_LINE_ITEM_MOTOR_POWER_OPTIONS(state, payload) {
      var shadeId = payload.id;

      if( payload.isExteriorShade ) {
        state.byId[shadeId].motor_power = 'AC Exterior';
      }
      state.byId[shadeId].motor_power_options = payload.motor_power_options;
      state.byId[shadeId].motor_sound_options = [];
    },

    SET_SHADE_LINE_ITEM_MOTOR_SOUND_OPTIONS(state, payload) {
      var shadeId = payload.id;
      if( payload.isExteriorShade ) {
        state.byId[shadeId].motor_power = 'AC Exterior';
      }
      state.byId[shadeId].motor_sound_options = payload.motor_sound_options;
    },

  //
  // Sort Order
  //
    SET_SORT_ORDER(state, shadeLineItems) {
      shadeLineItems.forEach(function(shade, index) {
        var swapIndex = state.byGroupId[shade.shade_group_id].shadeLineItems.map(s => s.id).indexOf(shade.id);
        state.byId[shade.id].isInnerCoupledShade = ( index <= 0 ) ? false : true;
        state.byId[shade.id].sort_order_number_textual = shade.sort_order_number_textual;
        state.byId[shade.id].sort_order_number = shade.sort_order_number;
        state.byGroupId[shade.shade_group_id].shadeLineItems.splice(index, 0, state.byGroupId[shade.shade_group_id].shadeLineItems.splice(swapIndex, 1)[0]);
      });
    },


    //
    // vuex-map-fields plugin
    //
    //updateField,

    //
    // This is an override of the updateField method from vuex...
    //
    updateField(state, { path, value }) {
      var splitPath = path.split(/[.[\]]+/),
          subjectShadeGroup = state.byGroupId[splitPath[1]],
          subjectShade = subjectShadeGroup.shadeLineItems[splitPath[3]],
          subjectShadeInitialState = null;

      if( typeof subjectShade !== 'undefined' && subjectShade.hasOwnProperty('id') ) {
        subjectShadeInitialState = state.initialStateById[subjectShade.id];
      }

      path.split(/[.[\]]+/).reduce((prev, key, index, array) => {
        if (array.length === index + 1) {
          // eslint-disable-next-line no-param-reassign
          prev[key] = value;

          //Special handling to account for measurement conversion
          if( key == 'actual_width' || key == 'actual_length' ) {
            let subjectValue = (value == null) ? null : parseFloat(value.toString().replace(/[^\d\.]/g, ""));
            let initialStateValue = (subjectShadeInitialState[key] == null) ? null : parseFloat(subjectShadeInitialState[key].toString().replace(/[^\d\.]/g, ""));

            //if the parseFloat values do not match, then we have changes...
            if( subjectValue != initialStateValue ) {
              prev['hasChanges'] = true;
            }

          } else {
            //If the new value is not equal to the value stored in initial state, then set the 'hasChanges' property to true
            //so we can be sure to trigger the save/update method on this shade.
            if( value != subjectShadeInitialState[key] && key != 'isMaximized' ) {
              prev['hasChanges'] = true;
            }
          }

          //If this is the parent of a coupled shade, and the property being updated exists within the COUPLED_SHADE_CHILD_LOCKED_PROPS array,
          //then update the child shades accordingly.
          if( subjectShade.isInnerCoupledShade == false && subjectShadeGroup.shadeLineItems.length > 1 ) {
            //Check to see if the field name (key) is in the COUPLED_SHADE_CHILD_LOCKED_PROPS array
            if( COUPLED_SHADE_CHILD_LOCKED_PROPS.includes(key) ) {
              subjectShadeGroup.shadeLineItems.forEach(function(shade){
                if( subjectShade.id != shade.id ) {
                  shade[key] = value;
                }
              });
            }
          }
        }

        return prev[key];
      }, state);
    }

  }
}
