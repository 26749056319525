//import { TOGGLE_SIDEBAR } from '../mutation-types'

import { ENDPOINT_SHADE_UNIT_CONFIG_ADD_ROW,
          ENDPOINT_SHADE_UNIT_CONFIG_DESTROY,
          ENDPOINT_SHADE_UNIT_CONFIG_CREATE,
          ENDPOINT_SHADE_UNIT_CONFIG_UPDATE,

          ENDPOINT_USER_SHADE_UNIT_CONFIG_ADD_ROW,
          ENDPOINT_USER_SHADE_UNIT_CONFIG_DESTROY,
          ENDPOINT_USER_SHADE_UNIT_CONFIG_CREATE,
          ENDPOINT_USER_SHADE_UNIT_CONFIG_UPDATE,
        } from '../../endpoints'
import { mapGetters, mapActions } from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import { v4 as uuidv4 } from 'uuid'

export const SHADE_UNIT_CONFIG_DEFAULTS = {
  product_model_shade_type: 'Interior',
  product_model_exterior_mfg: null,
  back_fascia: 0,
  wrapped_fascia_fabric: null,
  drive_type: null,
  dual_fabric_attribute_id: null,
  dual_fabric_id: null,
  dual_fabric_openness_factor_id: null,
  fabric_attribute_id: null,
  fabric_id: null,
  fabric_openness_factor_id: null,
  fascia_type: null,
  fascia_color: null,
  fascia_endcap_color: null,
  fascia_color_custom_manufacturer: null,
  fascia_color_custom_name: null,
  hasChanges: false,
  has_trim: 0,
  has_side_channel: 0,
  trim_type: null,
  hardware_color: null,
  hembar_style: null,
  hembar_color: null,
  hembar_endcap_color: null,
  hold_down: 1,
  id: null,
  motor_manufacturer_id: null,
  motor_power: null,
  motor_sound: null,
  motor_type: null,
  mount_type: null,
  name: null,
  pocket_closure_size: null,
  pocket_depth: null,
  pocket_height: null,
  pocket_include_closure: 0,
  preferred_fascia_size: null,
  product_model_drive_mechanism: null,
  product_model_dual_shade: 0,
  product_model_guided_cable: 0,
  product_model_bottom_up: null,
  roll_type: 'standard',
  side_channel_color: null,
  side_channel_color_custom_manufacturer: null,
  side_channel_color_custom_name: null,
  trim_color: null,
  trim_color_custom_manufacturer: null,
  trim_color_custom_name: null,
};

export const FABRIC_ONLY_SHADE_UNIT_CONFIG_DEFAULTS = {
  product_model_shade_type: 'Interior',
  product_model_exterior_mfg: null,
  back_fascia: 0,
  wrapped_fascia_fabric: null,
  drive_type: 'M-I',
  dual_fabric_attribute_id: null,
  dual_fabric_id: null,
  dual_fabric_openness_factor_id: null,
  fabric_attribute_id: null,
  fabric_id: null,
  fabric_openness_factor_id: null,
  fascia_type: null,
  fascia_color: null,
  fascia_endcap_color: null,
  has_trim: 0,
  has_side_channel: 0,
  trim_type: null,
  hardware_color: null,
  hembar_style: null,
  hembar_color: null,
  hembar_endcap_color: null,
  hold_down: 0,
  motor_manufacturer_id: null,
  motor_power: null,
  motor_sound: null,
  motor_type: null,
  mount_type: null,
  name: null,
  pocket_closure_size: null,
  pocket_depth: null,
  pocket_height: null,
  pocket_include_closure: 0,
  preferred_fascia_size: null,
  product_model_drive_mechanism: null,
  product_model_dual_shade: 0,
  product_model_guided_cable: 0,
  product_model_bottom_up: null,
  roll_type: 'standard',
  side_channel_color: null,
  trim_color: null,
};

export const FABRIC_PANEL_ONLY_PERMITTED_FIELDS = [
  'name',
  'product_model_shade_type', 'product_model_exterior_mfg',
  'product_model_drive_mechanism',
  'roll_type', 'product_model_dual_shade', 'product_model_guided_cable', 'product_model_bottom_up',
  'fabric_id', 'fabric_openness_factor_id', 'fabric_attribute_id',
  'dual_fabric_id', 'dual_fabric_openness_factor_id', 'dual_fabric_attribute_id',
  'hembar_style', 'hembar_color', 'hembar_endcap_color'
];


export default {
  namespaced: true,


  //state
  state: {
    byId: {},
    initialStateById: {},
    errors: {}
  },


  // getters
  getters: {
    //
    // Shade Unit Configs (list/multiple) Getters
    //
    shadeUnitConfigs: state => state.byId,

    //
    // Flattened Data Approach
    //
    find: state => id => state.byId[id],

    //
    // Check if this is a shade unit config for an estimate, or a user global shade unit config
    //
    isUserGlobalConfig: (state, getters) => (id) => {
      let targetUnit = getters.find(id);
      return ( targetUnit.is_user_global_config == true ) ? true : false;
    },

    //
    // Check if shade is exterior shade
    isExteriorShade: (state, getters) => (id) => {
      let shadeUnit = getters.find(id),
          shadeType = ( shadeUnit.product_model_shade_type ) ? shadeUnit.product_model_shade_type.toLowerCase() : null;
      return ( shadeType == 'exterior' ) ? true : false;
    },

    //
    // Get the Add Row Endpoint
    //
    getEndpointAddRow: (state, getters) => (isUserGlobalConfig) => {
      return ( isUserGlobalConfig == true ) ? ENDPOINT_USER_SHADE_UNIT_CONFIG_ADD_ROW : ENDPOINT_SHADE_UNIT_CONFIG_ADD_ROW;
    },

    //
    // Get the Destroy Endpoint
    //
    getEndpointDestroy: (state, getters) => (id) => {
      let isUserGlobalConfig = getters.isUserGlobalConfig(id);
      let endpoint = ( isUserGlobalConfig == true ) ? ENDPOINT_USER_SHADE_UNIT_CONFIG_DESTROY : ENDPOINT_SHADE_UNIT_CONFIG_DESTROY;
      return endpoint + id;
    },

    //
    // Get the Create Endpoint
    //
    getEndpointCreate: (state, getters, rootState, rootGetters) => (id) => {
      let isUserGlobalConfig = getters.isUserGlobalConfig(id);
      if( isUserGlobalConfig == true ) {
        return ENDPOINT_USER_SHADE_UNIT_CONFIG_CREATE;
      } else {
        return ENDPOINT_SHADE_UNIT_CONFIG_CREATE + rootGetters['shadeOrder/shadeOrder'].id;
      }
    },

    //
    // Get the Update Endpoint
    //
    getEndpointUpdate: (state, getters) => (id) => {
      let isUserGlobalConfig = getters.isUserGlobalConfig(id);
      let endpoint = ( isUserGlobalConfig == true ) ? ENDPOINT_USER_SHADE_UNIT_CONFIG_UPDATE : ENDPOINT_SHADE_UNIT_CONFIG_UPDATE;
      return endpoint + id;
    },

    //
    // Check if config unit has a "Fascia" fascia_type
    //
    hasFascia: (state, getters) => (id) => {
      var unit = getters.find(id);
      var fasciaOptions = [
        'Fascia',
        'Fabric Wrapped Fascia',
        'LV Cassette w/Fabric Insert',
        'RO P95 Cassette w/o Fabric Wrap',
        'RO P95 Cassette w/Fabric Wrap'
      ];
      return ( fasciaOptions.includes(unit.fascia_type) ) ? true : false;
    },

    //
    // Check if config unit has a fabric wrapped fascia_type
    //
    hasFabricWrappedFascia: (state, getters) => (id) => {
      var unit = getters.find(id);
      var fasciaOptions = [
        'Fabric Wrapped Fascia',
        'LV Cassette w/Fabric Insert',
        'RO P95 Cassette w/Fabric Wrap'
      ];
      return ( fasciaOptions.includes(unit.fascia_type ) ) ? true : false;
    },

    //
    // Get a list of saved shade unit configs (isNew !== true)
    //
    appliedShadeUnitConfigs: state => {
      let configUnits = Object.keys(JSON.parse(JSON.stringify(state.byId)));

      let list = [];
      configUnits.forEach(function(id, index) {
        if( state.byId[id].isNew !== true ) {
          list.push( state.byId[id] );
        }
      });
      return list;
    },

    shadeLineItems: (state, rootGetters, rootState, store) => (id) => {
      var shadeLineItemsWithConfig = {
        new: store['shadeLineItems/list'].filter(shadeLineItem => shadeLineItem.shade_unit_config_id == id && shadeLineItem.isNew == true),
        existing: store['shadeLineItems/list'].filter(shadeLineItem => shadeLineItem.shade_unit_config_id == id)
      };
      return shadeLineItemsWithConfig;
    },

    //
    // Fabric summary text
    //
    fabricSummaryText: (state, rootState, store, getters) => (id) => {
      let shadeUnit = getters['shadeUnitConfigs/find'](id),
          isExteriorShade = getters['shadeUnitConfigs/isExteriorShade'](id);

      if( isExteriorShade ) {
        var fabricOptions = store.estimationSelectOptions.primaryShadeSelectOptions.exteriorFabricOptions;
      } else {
        var fabricOptions = store.estimationSelectOptions.primaryShadeSelectOptions.interiorFabricOptions;
      }

      if( typeof shadeUnit === 'undefined' || !shadeUnit.fabric_attribute_id ) {
        return null;
      }

      var fabric = shadeUnit.fabric_attribute_options.find(fabColor => fabColor.id == shadeUnit.fabric_attribute_id);
      var fabricOpenness = shadeUnit.fabric_openness_factor_options.find(fabOpenness => fabOpenness.id == shadeUnit.fabric_openness_factor_id);
      var fabricMan = '';
      var fabricManufacturer = '';

      for(fabricManufacturer in fabricOptions) {
        fabricMan = fabricOptions[fabricManufacturer].find(fabMfr => fabMfr.id == shadeUnit.fabric_id);
        if( fabricMan ) {
          break;
        }
      }
      return fabricMan.name + ' ' + fabric.color + ' ' + fabricOpenness.name;
    },

    //
    // Dual Fabric summary text
    //
    dualFabricSummaryText: (state, rootState, store, getters) => (id) => {
      let shadeUnit = getters['shadeUnitConfigs/find'](id),
          isExteriorShade = getters['shadeUnitConfigs/isExteriorShade'](id);

      if( isExteriorShade ) {
        var fabricOptions = store.estimationSelectOptions.primaryShadeSelectOptions.exteriorFabricOptions;
      } else {
        var fabricOptions = store.estimationSelectOptions.primaryShadeSelectOptions.interiorFabricOptions;
      }

      if( typeof shadeUnit === 'undefined' || !shadeUnit.dual_fabric_attribute_id ) {
        return null;
      }

      var fabric = shadeUnit.dual_fabric_attribute_options.find(fabColor => fabColor.id == shadeUnit.dual_fabric_attribute_id);
      var fabricOpenness = shadeUnit.dual_fabric_openness_factor_options.find(fabOpenness => fabOpenness.id == shadeUnit.dual_fabric_openness_factor_id);
      var fabricMan = '';
      var fabricManufacturer = '';

      for(fabricManufacturer in fabricOptions) {
        fabricMan = fabricOptions[fabricManufacturer].find(fabMfr => fabMfr.id == shadeUnit.dual_fabric_id);
        if( fabricMan ) {
          break;
        }
      }
      return fabricMan.name + ' ' + fabric.color + ' ' + fabricOpenness.name;
    },

    //
    //Retrieve/return array of permitted fields for fabric panel only shade units
    //
    fabricPanelOnlyPermittedFields: (state) => {
      return FABRIC_PANEL_ONLY_PERMITTED_FIELDS;
    },

    //
    // Errors by record id
    //
    errorsByRecordId: (state) => (id) => {
      if( state.errors.hasOwnProperty(id) ) {
        return state.errors[id];
      } else {
        return {};
      }
    },

    //
    // vuex-map-fields plugin
    //
    getField,

  }, //end getters


  // actions
  actions: {
    //
    // Initial load of shade unit configs and corresponding settings
    //
    initShadeUnitConfigs({dispatch}, payload) {
      dispatch('applyShadeUnitConfigs', payload.shadeUnitConfigs);
    },

    //
    // Load the initial set of shade unit config records into state
    //
    applyShadeUnitConfigs({commit}, shadeUnitConfigs) {
      if( shadeUnitConfigs ) {
        shadeUnitConfigs.forEach(function(shadeConfig, index) {
          shadeConfig.hasChanges = false;
          shadeConfig.isNew = false;
          shadeConfig.isMaximized = false;

          commit('SET_SHADE_UNIT_CONFIG', shadeConfig);
        });
      }
    },

    //
    // Add a new shade unit config record to state (not saved yet)
    //
    addNewShadeUnitConfig({commit, getters}, payload) {
      axios.get( getters.getEndpointAddRow(payload.isUserGlobalConfig) )
      .then((response) => {
        var newShadeUnit = {...SHADE_UNIT_CONFIG_DEFAULTS, ...response.data.config_unit};
        commit('ADD_NEW_SHADE_UNIT_CONFIG', newShadeUnit);
      })
      .catch(error => {
        //flash(error.response.data.status_type, error.response.data.status);
      });
    },

    //
    // Apply (save) a shade unit config to the database
    //   AND ensure that any attached shade line items are also updated to reflect the changes made to the config unit
    //
    applyUpdatedState({commit, getters, dispatch}, id) {
      let thisShadeUnit = getters.find(id);

      if( thisShadeUnit.isNew === true ) {
        var endpointSaveShadeUnitConfig = getters.getEndpointCreate(id),
            targetShadeUnit = thisShadeUnit,
            submitData = {
              _method: 'POST',
              shade_unit_config: {
                [targetShadeUnit.id]: targetShadeUnit
              }
            };

      } else {
        var endpointSaveShadeUnitConfig = getters.getEndpointUpdate(id),
            targetShadeUnit = thisShadeUnit,
            submitData = {
              _method: 'PUT',
              shade_unit_config: {
                [targetShadeUnit.id]: targetShadeUnit
              }
            };
      }

      axios.post(endpointSaveShadeUnitConfig, submitData)
      .then((response) => {
        let savedShadeUnitConfig = response.data.data.shadeUnitConfig;
          delete savedShadeUnitConfig.order_items;
          delete savedShadeUnitConfig.shade_order;

        //Unit has been updated - it no longer has changes
        savedShadeUnitConfig.hasChanges = false;
        savedShadeUnitConfig.isMaximized = false;

        if( thisShadeUnit.isNew === true ) {
          savedShadeUnitConfig.old_id = thisShadeUnit.id;
          commit('CREATE_SHADE_UNIT_CONFIG', savedShadeUnitConfig);
        } else {
          commit('UPDATE_SHADE_UNIT_CONFIG', savedShadeUnitConfig);
        }

        if( response.data.data.hasOwnProperty('shadeGroups') && response.data.data.shadeGroups.length > 0 ) {
          $.each(response.data.data.shadeGroups, function(index, shadeGroup) {
            shadeGroup.isUpdate = true;
          });

          dispatch('shadeGroups/applyShadeGroups', response.data.data.shadeGroups, {root: true});
        }

        if( response.data.data.hasOwnProperty('orderProposal') ) {
          //update the orderProposal
          dispatch('orderProposal/applyOrderProposal', response.data.data.orderProposal, {root: true});
        }

        //clear form errors
        dispatch('shadeUnitConfigs/setFormErrors', {}, {root: true});

        //Flash message
        flash(response.data.status_type, response.data.status);
      })
      .catch(error => {
        //dispatch the setFormErrors action
        dispatch('shadeUnitConfigs/setFormErrors', error.response.data.errors, {root: true});

        //Show the error
        flash(error.response.data.status_type, error.response.data.status);
      });
    },

    //
    // If a user chooses not to apply the changes made to a shade unit config, revert the unit back to the initial state.
    //
    revertToInitialState({commit, getters}, id) {
      let targetUnit = getters.find(id);
      commit('REVERT_SHADE_UNIT_CONFIG_TO_INITIAL_STATE', targetUnit);
      flash('alert-warning', 'Configuration changes discarded.');
    },

    //
    // Delete a shade unit config record
    //
    deleteShadeUnitConfig({commit, getters, dispatch}, id) {
      var targetUnit = getters.find(id);

      if( targetUnit.isNew == true ) {
        //this is a new unit - just remove the index from the shadeUnitConfigs array
        commit('DELETE_SHADE_UNIT_CONFIG', targetUnit);

      } else {
        axios.delete( getters.getEndpointDestroy(targetUnit.id) )
        .then((response) => {
          commit('DELETE_SHADE_UNIT_CONFIG', targetUnit);

          //TODO: See shadeUnitConfigController->delete() for additional notes..
          if( response.data.data.hasOwnProperty('shadeGroups') && response.data.data.shadeGroups.length > 0 ) {
            $.each(response.data.data.shadeGroups, function(index, shadeGroup) {
              shadeGroup.isUpdate = true;
            });

            dispatch('shadeGroups/applyShadeGroups', response.data.data.shadeGroups, {root: true});
          }

          flash(response.data.status_type, response.data.status);
        })
        .catch(error => {
          flash(error.response.data.status_type, error.response.data.status);
        });
      }
    },

    //
    // Apply Default Shade Properties when Fabric Only is selected
    //
    applyDefaultShadePropsForFabricOnly({commit, state, getters, rootGetters}, id) {
      let shadeUnitConfig = _.cloneDeep(getters.find(id)),
          shadeUnitDefaults = FABRIC_ONLY_SHADE_UNIT_CONFIG_DEFAULTS,
          permittedFabricPanelOnlyFields = FABRIC_PANEL_ONLY_PERMITTED_FIELDS;

      Object.entries(shadeUnitDefaults).forEach(([key, value]) => {
        if( !permittedFabricPanelOnlyFields.includes(key) ) {
          shadeUnitConfig[key] = value;
        }
      });
      commit('UPDATE_SHADE_UNIT_CONFIG_FOR_FABRIC_ONLY', shadeUnitConfig);
    },

    //
    // Remove Default Shade Properties when Fabric Only is deselected
    //  (product_model_drive_mechanism changes from 'fabric_only' to some other value)
    //
    removeShadePropsForFabricOnly({commit, getters, rootGetters}, id) {
      let shadeUnitConfig = _.clone(getters.find(id)),
          shadeUnitDefaults = SHADE_UNIT_CONFIG_DEFAULTS,
          excludeFromReset = ['name', 'id', 'drive_type'];

      Object.entries(shadeUnitDefaults).forEach(([key, value]) => {
        if( !excludeFromReset.includes(key) ) {
          shadeUnitConfig[key] = value;
        }
      });
      commit('UPDATE_SHADE_UNIT_CONFIG_FOR_FABRIC_ONLY', shadeUnitConfig);
    },

    //
    // Set form validation errors
    //
    setFormErrors({commit, state, getters}, payload) {
      //Set shade unit config form fields...
      if( payload.hasOwnProperty('shade_unit_config') ) {
        commit('SET_SHADE_UNIT_CONFIG_ERRORS', payload.shade_unit_config);
      } else {
        commit('SET_SHADE_UNIT_CONFIG_ERRORS', {});
      }
    },

  //
  // Actions for Select Options
  //
    //
    // Set Fabric Openness Factor select options for a given shade config unit
    setFabricOpennessFactorOptions({commit, getters, dispatch}, payload) {
      let targetUnit = getters.find(payload.id),
          reqParams = new URLSearchParams();
            reqParams.append('fabric_manufacturer_id', payload.fabric_manufacturer_id);

      dispatch('estimationSelectOptions/getFabricOpennessFactorOptions', {reqParams: reqParams}, {root: true})
      .then(response => {
        let commitData = {
          shadeUnitConfig: targetUnit,
          fabric_number: payload.fabric_number,
          fabric_openness_options: response.data.data,
        };
        commit('SET_SHADE_UNIT_CONFIG_FABRIC_OPENNESS_FACTOR_OPTIONS', commitData);

      }, error => {
        flash(error.response.data.status_type, error.response.data.status);
      });
    },

    //
    // Set Fabric Color select options for a given shade config unit
    setFabricColorOptions({commit, getters, dispatch}, payload) {
      let targetUnit = getters.find(payload.id),
          reqParams = new URLSearchParams();
            reqParams.append('fabric_openness_id', payload.fabric_openness_id);

      dispatch('estimationSelectOptions/getFabricColorOptions', {reqParams: reqParams}, {root: true})
      .then(response => {
        let commitData = {
          shadeUnitConfig: targetUnit,
          fabric_number: payload.fabric_number,
          fabric_color_options: response.data.data,
        };
        commit('SET_SHADE_UNIT_CONFIG_FABRIC_COLOR_OPTIONS', commitData);

      }, error => {
        flash(error.response.data.status_type, error.response.data.status);
      });
    },

    setMotorTypeOptions({commit, getters, dispatch}, payload) {
      let targetUnit = getters.find(payload.id),
          isExteriorShade = getters.isExteriorShade(payload.id),
          reqParams = new URLSearchParams();
            reqParams.append('motor_manufacturer_id', targetUnit.motor_manufacturer_id);

      return new Promise((resolve, reject) => {
        dispatch('estimationSelectOptions/getMotorTypeOptions', {reqParams: reqParams, isExteriorShade: isExteriorShade}, {root: true})
        .then(response => {
          let commitData = {
            shadeUnitConfig: targetUnit,
            isExteriorShade: isExteriorShade,
            motor_type_options: response.motorTypeOptions
          };
          commit('SET_SHADE_UNIT_CONFIG_MOTOR_TYPE_OPTIONS', commitData);
          resolve(response);
        }, error => {
          flash(error.response.data.status_type, error.response.data.status);
          reject(error);
        });
      });
    },

    //
    // Set Motor Power select options for a given shade config unit
    setMotorPowerOptions({commit, getters, dispatch}, payload) {
      let targetUnit = getters.find(payload.id),
          isExteriorShade = getters.isExteriorShade(payload.id),
          reqParams = new URLSearchParams();
            reqParams.append('motor_manufacturer_id', targetUnit.motor_manufacturer_id);
            reqParams.append('motor_type', targetUnit.motor_type);

      return new Promise((resolve, reject) => {
        dispatch('estimationSelectOptions/getMotorPowerOptions', {reqParams: reqParams, isExteriorShade: isExteriorShade}, {root: true})
        .then(response => {
          let commitData = {
            shadeUnitConfig: targetUnit,
            isExteriorShade: isExteriorShade,
            motor_power_options: response.motorPowerOptions
          };
          commit('SET_SHADE_UNIT_CONFIG_MOTOR_POWER_OPTIONS', commitData);
          resolve(response);
        }, error => {
          flash(error.response.data.status_type, error.response.data.status);
          reject(error);
        });
      });
    },

    //
    // Set Motor Sound select options for a given shade config unit
    setMotorSoundOptions({commit, getters, dispatch}, payload) {
      let targetUnit = getters.find(payload.id),
          isExteriorShade = getters.isExteriorShade(payload.id),
          reqParams = new URLSearchParams();
            reqParams.append('motor_manufacturer_id', targetUnit.motor_manufacturer_id);
            reqParams.append('motor_type', targetUnit.motor_type);
            reqParams.append('motor_power', targetUnit.motor_power);
            reqParams.append('is_exterior_shade', (isExteriorShade == true) ? 1 : 0);

      return new Promise((resolve, reject) => {
        dispatch('estimationSelectOptions/getMotorSoundOptions', {reqParams: reqParams}, {root: true})
        .then(response => {
          let commitData = {
            shadeUnitConfig: targetUnit,
            isExteriorShade: getters.isExteriorShade(payload.id),
            motor_sound_options: response.data.data
          };
          commit('SET_SHADE_UNIT_CONFIG_MOTOR_SOUND_OPTIONS', commitData);
          resolve(response);
        }, error => {
          flash(error.response.data.status_type, error.response.data.status);
          reject(error);
        });
      });
    },

  }, //end actions


  // mutations
  mutations: {
    SET_SHADE_UNIT_CONFIG(state, shadeUnitConfig) {
      Vue.set(state.byId, shadeUnitConfig.id, shadeUnitConfig);
      Vue.set(state.initialStateById, shadeUnitConfig.id, _.cloneDeep(shadeUnitConfig));
    },

    ADD_NEW_SHADE_UNIT_CONFIG(state, newShadeUnitConfig) {
      newShadeUnitConfig.id = uuidv4();
      newShadeUnitConfig.isNew = true;
      newShadeUnitConfig.isMaximized = true;

      Vue.set(state.byId, newShadeUnitConfig.id, newShadeUnitConfig);
      Vue.set(state.initialStateById, newShadeUnitConfig.id, _.cloneDeep(newShadeUnitConfig));
    },

    CREATE_SHADE_UNIT_CONFIG(state, shadeUnitConfig) {
      var item = state.byId[shadeUnitConfig.id];
      if( typeof item == 'undefined' || item == null ) {
        item = state.byId[shadeUnitConfig.old_id];
      }

      item = _.merge(item, shadeUnitConfig);
        item.isNew = false;
        item.hasChanges = false;

      //Set state for shade line items byId
      Vue.set(state.byId, item.id, item);
      Vue.set(state.initialStateById, item.id, _.cloneDeep(item));

      if( item.old_id != item.id ) {
        Vue.delete(state.byId, item.old_id);
        Vue.delete(state.initialStateById, item.old_id);
      }
    },

    UPDATE_SHADE_UNIT_CONFIG(state, shadeUnitConfig) {
      let item = state.byId[shadeUnitConfig.id];
        item = _.merge(item, shadeUnitConfig);
        item.isNew = false;
        item.isMaximized = false;

      Vue.set(state.byId, item.id, item);
      Vue.set(state.initialStateById, item.id, _.cloneDeep(item));
    },

    UPDATE_SHADE_UNIT_CONFIG_FOR_FABRIC_ONLY(state, shadeUnitConfig) {
      shadeUnitConfig = _.merge(state.byId[shadeUnitConfig.id], shadeUnitConfig);
      Vue.set(state.byId, shadeUnitConfig.id, shadeUnitConfig);

      //DO NOT Update the initial state object
    },

    REVERT_SHADE_UNIT_CONFIG_TO_INITIAL_STATE(state, shadeUnitConfig) {
      let initialStateRecord = state.initialStateById[shadeUnitConfig.id];
      Vue.set(state.byId, initialStateRecord.id, _.cloneDeep(initialStateRecord));
    },

    DELETE_SHADE_UNIT_CONFIG(state, shadeUnitConfig) {
      Vue.delete(state.byId, shadeUnitConfig.id);
      Vue.delete(state.initialStateById, shadeUnitConfig.id);
    },

  //
  // Fabric Select Option Setters
  //
    SET_SHADE_UNIT_CONFIG_FABRIC_OPENNESS_FACTOR_OPTIONS(state, payload) {
      let shadeUnitConfig = payload.shadeUnitConfig;
      if( payload.fabric_number == 'fabric_2' ) {
        state.byId[shadeUnitConfig.id].dual_fabric_openness_factor_id = null;
        state.byId[shadeUnitConfig.id].dual_fabric_openness_factor_options = payload.fabric_openness_options;
        state.byId[shadeUnitConfig.id].dual_fabric_attribute_id = null;
        state.byId[shadeUnitConfig.id].dual_fabric_attribute_options = {};
      } else {
        state.byId[shadeUnitConfig.id].fabric_openness_factor_id = null;
        state.byId[shadeUnitConfig.id].fabric_openness_factor_options = payload.fabric_openness_options;
        state.byId[shadeUnitConfig.id].fabric_attribute_id = null;
        state.byId[shadeUnitConfig.id].fabric_attribute_options = {};
      }
    },

    SET_SHADE_UNIT_CONFIG_FABRIC_COLOR_OPTIONS(state, payload) {
      let shadeUnitConfig = payload.shadeUnitConfig;
      if( payload.fabric_number == 'fabric_2' ) {
        state.byId[shadeUnitConfig.id].dual_fabric_attribute_id = null;
        state.byId[shadeUnitConfig.id].dual_fabric_attribute_options = payload.fabric_color_options;
      } else {
        state.byId[shadeUnitConfig.id].fabric_attribute_id = null;
        state.byId[shadeUnitConfig.id].fabric_attribute_options = payload.fabric_color_options;
      }
    },

  //
  // Motor Select Option Setters
  //
    SET_SHADE_UNIT_CONFIG_MOTOR_TYPE_OPTIONS(state, payload) {
      let shadeUnitConfig = payload.shadeUnitConfig;
      if( payload.isExteriorShade ) {
        state.byId[shadeUnitConfig.id].motor_type = 'Wireless';
        state.byId[shadeUnitConfig.id].motor_power = 'AC Exterior';
        state.byId[shadeUnitConfig.id].motor_type_options = payload.motor_type_options;
        state.byId[shadeUnitConfig.id].motor_sound_options = [];
      } else {
        state.byId[shadeUnitConfig.id].motor_type_options = payload.motor_type_options;
        state.byId[shadeUnitConfig.id].motor_power_options = [];
        state.byId[shadeUnitConfig.id].motor_sound_options = [];
      }
    },

    SET_SHADE_UNIT_CONFIG_MOTOR_POWER_OPTIONS(state, payload) {
      let shadeUnitConfig = payload.shadeUnitConfig;
      if( payload.isExteriorShade ) {
        state.byId[shadeUnitConfig.id].motor_power = 'AC Exterior';
      }
      state.byId[shadeUnitConfig.id].motor_power_options = payload.motor_power_options;
      state.byId[shadeUnitConfig.id].motor_sound_options = [];
    },

    SET_SHADE_UNIT_CONFIG_MOTOR_SOUND_OPTIONS(state, payload) {
      let shadeUnitConfig = payload.shadeUnitConfig;
      if( payload.isExteriorShade ) {
        state.byId[shadeUnitConfig.id].motor_power = 'AC Exterior';
      }
      state.byId[shadeUnitConfig.id].motor_sound_options = payload.motor_sound_options;
    },

    SET_SHADE_UNIT_CONFIG_ERRORS(state, errors) {
      state.errors = errors;
    },

    //
    // Override updateField method from Vuex
    //
    updateField(state, { path, value }) {
      var splitPath = path.split(/[.[\]]+/),
          subjectShade = state.byId[splitPath[1]],
          subjectShadeInitialState = null;

      if( typeof subjectShade !== 'undefined' && subjectShade.hasOwnProperty('id') ) {
        subjectShadeInitialState = state.initialStateById[subjectShade.id];
      }

      path.split(/[.[\]]+/).reduce((prev, key, index, array) => {
        if (array.length === index + 1) {
          // eslint-disable-next-line no-param-reassign
          prev[key] = value;

          //If the new value is not equal to the value stored in initial state, then set the 'hasChanges' property to true
          //so we can be sure to trigger the save/update method on this shade.
          if( value != subjectShadeInitialState[key] && key != 'isMaximized' ) {
            prev['hasChanges'] = true;
          }
        }

        return prev[key];
      }, state);
    }
  } //end MUTATIONS
}
