var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-row additional-item-container" }, [
    _vm.showHeadingRow
      ? _c("div", { staticClass: "form-group col-sm-12 mb-0" }, [
          _c("h2", [_vm._v(_vm._s(_vm.headingRowHeadingText))]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "form-group col-sm-12" }, [
      _c("div", { staticClass: "table-responsive" }, [
        _c(
          "table",
          { staticClass: "table additional-items-table" },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm.orderItemAdditions.length > 0
              ? [
                  _vm._l(
                    _vm.orderItemAdditions,
                    function (additionalItem, index) {
                      return [
                        _c("order-item-addition", {
                          attrs: {
                            "additional-item": additionalItem,
                            "additional-item-id": additionalItem.id,
                          },
                        }),
                      ]
                    }
                  ),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "colored-bg text-center",
                    attrs: { colspan: "8" },
                  },
                  [
                    _c(
                      "a",
                      {
                        class: _vm.orderProposal.userCanEdit ? "" : "disabled",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            return _vm.addOrderItemAdditionRecord($event)
                          },
                        },
                      },
                      [_vm._v(" Add Item")]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "text-right", attrs: { colspan: "6" } },
                  [_vm._v("Total")]
                ),
                _vm._v(" "),
                _c("td", [
                  _vm._v(_vm._s(_vm.orderProposal.addtl_items_total_formatted)),
                ]),
                _vm._v(" "),
                _c("td"),
              ]),
            ]),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "part-id" }, [_vm._v("Product Name")]),
        _vm._v(" "),
        _c("th", { staticClass: "part-color-id" }, [_vm._v("Color")]),
        _vm._v(" "),
        _c("th", { staticClass: "part-number" }, [_vm._v("Part #")]),
        _vm._v(" "),
        _c("th", { staticClass: "part-uom" }, [_vm._v("UOM")]),
        _vm._v(" "),
        _c("th", { staticClass: "part-quantity" }, [_vm._v("Quantity")]),
        _vm._v(" "),
        _c("th", { staticClass: "part-unit-cost" }, [_vm._v("Cost")]),
        _vm._v(" "),
        _c("th", { staticClass: "part-total-cost" }, [_vm._v("Total")]),
        _vm._v(" "),
        _c("th", { staticClass: "part-actions" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }